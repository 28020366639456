import { useFirebase } from "./FirebaseProvider"
import React, { FC, createContext, useEffect, useState } from "react"
import { ServiceStatus } from "@ap-frontend/types"
import { doc, onSnapshot } from "firebase/firestore"

export const ServiceStatusContext = createContext<ServiceStatus | undefined>(
  undefined
)
ServiceStatusContext.displayName = "ServiceStatusContext"

export const ServiceStatusProvider: FC = ({ children }) => {
  const [data, setData] = useState<ServiceStatus | undefined>(undefined)

  const { firestore } = useFirebase()

  useEffect(() => {
    if (!firestore) return

    const unsubscribe = onSnapshot(doc(firestore, "service", "status"), doc => {
      setData(doc.data() as ServiceStatus)
    })

    return () => {
      unsubscribe()
    }
  }, [firestore])

  return (
    <ServiceStatusContext.Provider value={data}>
      {children}
    </ServiceStatusContext.Provider>
  )
}
