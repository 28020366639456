import React, { ReactElement } from "react"
import ReactMarkdown from "react-markdown"
import remarkGfm from "remark-gfm"
import T from "@mui/material/Typography"
import Link from "./Link"
import ListItem from "@mui/material/ListItem"
import List from "@mui/material/List"

/**
 * Render string as markdown
 *
 * @remarks
 * This component parses a string with react-markdown, configured to open links in new tabs and render with material UI components.
 *
 * @returns ReactElement
 */
const Markdown = ({
  children,
  typographyVariant = "body1",
}: Pick<React.ComponentProps<typeof ReactMarkdown>, "children"> & {
  typographyVariant?: "body1" | "body2"
}): ReactElement => {
  /* eslint-disable @typescript-eslint/no-unused-vars */
  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm]}
      components={{
        h1: ({ node, ...props }) => (
          <T variant="h1" component="h1" {...props} />
        ),
        h2: ({ node, ...props }) => (
          <T variant="h2" component="h2" {...props} />
        ),
        h3: ({ node, ...props }) => (
          <T variant="h3" component="h3" {...props} />
        ),
        h4: ({ node, ...props }) => (
          <T variant="h4" component="h4" {...props} />
        ),
        p: ({ node, ...props }) => (
          <T variant={typographyVariant} component="p" {...props} />
        ),
        ul: ({ node, ...props }) => {
          const { ordered, ...rest } = props
          return <List className="MuiList-unordered" component="ul" {...rest} />
        },
        ol: ({ node, ...props }) => {
          const { ordered, ...rest } = props
          return <List className="MuiList-ordered" component="ol" {...rest} />
        },
        li: ({ node, children, ...props }) => (
          <ListItem {...props}>
            <T variant={typographyVariant} component="div">
              {children}
            </T>
          </ListItem>
        ),
        a: ({ node, ...props }) => <Link {...props} />,
      }}
    >
      {children}
    </ReactMarkdown>
    /* eslint-enable */
  )
}

export default Markdown
