import * as React from "react"
import Button from "../components/design-system/Button"
import Card from "@mui/material/Card"
import T from "@mui/material/Typography"
import { styled } from "@mui/material/styles"
import ButtonWrapper from "./ButtonWrapper"
import { navigate } from "gatsby"

const ErrorCard = styled(Card)(({ theme }) => ({
  boxShadow: "none",
  padding: theme.spacing(6),
  justifyContent: "center",
  alignContent: "center",
  flexDirection: "column",
  textAlign: "center",
}))

const ErrorFallback: React.FC = () => {
  return (
    <ErrorCard role="alert">
      <T variant="h3" sx={{ mb: 2 }}>
        There was an unexpected error.
      </T>
      <ButtonWrapper>
        <Button
          onClick={() => navigate("/")}
          role="link"
          data-test-button="retry"
        >
          Try again
        </Button>
      </ButtonWrapper>
    </ErrorCard>
  )
}

export default ErrorFallback
