import React, { ReactElement } from "react"
import Alert from "@mui/material/Alert"
import Box from "@mui/material/Box"
import AlertTitle from "@mui/material/AlertTitle"
import useAlerts from "../hooks/useAlerts"
import Markdown from "./Markdown"
import Link from "./Link"
import T from "@mui/material/Typography"

const Alerts = (): ReactElement => {
  const alerts = Alerts.useAlerts()

  if (!alerts.length) return <></>

  return (
    <Box sx={{ mb: 3 }}>
      {alerts.map(alert => (
        <Alert
          key={alert.id}
          severity={alert.severity}
          data-test-id={`alert-wrapper-${alert.id}`}
        >
          {alert.title && (
            <AlertTitle data-test-id={`alert-title-${alert.id}`}>
              {alert.title}
            </AlertTitle>
          )}
          <Markdown>{alert.message}</Markdown>
          {alert.action &&
            (alert.action.to !== undefined ||
              alert.action.href !== undefined) && (
              <T sx={{ mt: -1 }}>
                <Link to={alert.action.to} href={alert.action.href}>
                  {alert.action.text}
                </Link>
              </T>
            )}
        </Alert>
      ))}
    </Box>
  )
}

Alerts.useAlerts = useAlerts

export default Alerts
