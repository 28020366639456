import React, { PropsWithChildren, ReactElement } from "react"
import AppBar from "@mui/material/AppBar"
import Avatar from "@mui/material/Avatar"
import Box from "@mui/material/Box"
import Container from "@mui/material/Container"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import Skeleton from "../Skeleton"
import T from "@mui/material/Typography"
import { styled } from "@mui/material/styles"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import Button from "../design-system/Button"
import Logo from "../Logo"
import useUserWithSignInRedirect from "../../hooks/useUserWithSignInRedirect"
import { navigate } from "gatsby"
import { SkipNavLink, SkipNavContent } from "@reach/skip-nav"
import { QuestionAnswerProvider } from "../../providers/QuestionAnswerProvider"
import { visuallyHidden } from "@mui/utils"
import Link from "../Link"
import Footer from "../Footer"
import { colours } from "../../data/helix-tokens"
import { AlertsProvider } from "../../providers/AlertsProvider"
import Alerts from "../Alerts"
import NoScript from "../NoScript"
import usePath from "../../hooks/usePath"

const SkipNav = styled(SkipNavLink)(({ theme }) => ({
  border: "0px solid #B8B8B8",
  height: "1px",
  width: "1px",
  margin: "-1px",
  padding: "0",
  overflow: "hidden",
  position: "absolute",
  "&:focus": {
    padding: "1rem",
    position: "fixed",
    top: "10px",
    left: "10px",
    background: "white",
    zIndex: theme.zIndex.drawer + 1,
    width: "auto",
    height: "auto",
    clip: "auto",
  },
}))

export const HomeLink = styled(Link)(({ theme }) => ({
  "& .MuiSvgIcon-root": {
    marginRight: theme.spacing(1),
    verticalAlign: "text-bottom",
  },
}))

export const UserMenu = (): ReactElement => {
  const { signOut, user } = SignedInLayout.useUserWithSignInRedirect()
  const [userMenu, setUserMenu] = React.useState<Element | null>(null)

  return (
    <>
      <Button
        aria-controls={userMenu ? "user-actions-menu" : ""}
        variant="text"
        aria-haspopup="true"
        onClick={event => setUserMenu(event.currentTarget)}
        data-test-id="user-menu-dropdown-button"
        sx={{
          ml: "auto",
        }}
      >
        <Avatar
          variant="rounded"
          sx={{
            ml: "auto",
            mr: 1.5,
          }}
          data-test-id="user-avatar"
        >
          <Box sx={{ zIndex: 1 }}>
            {user?.displayName ? user.displayName[0] : ""}
          </Box>
        </Avatar>
        <T
          variant="body2"
          component="span"
          sx={{
            textTransform: "none",
            fontWeight: theme => theme.typography.fontWeightMedium,
            marginBottom: 0,
            marginRight: 1,
            color: "text.primary",
            display: ["none", "block"],
          }}
          data-test-id="signed-in-user"
        >
          {user === undefined && <Skeleton width="18ch" />}
          {user?.displayName}
        </T>
        <ArrowDropDownIcon />
        <Box sx={visuallyHidden}>{userMenu ? "Hide" : "Show"} user menu</Box>
      </Button>
      <Menu
        id="user-actions-menu"
        anchorEl={userMenu}
        open={Boolean(userMenu)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        color="primary"
        onClose={() => setUserMenu(null)}
        aria-expanded={Boolean(userMenu)}
        aria-hidden={!userMenu}
        data-test-id="user-menu"
        hidden={!userMenu}
      >
        <MenuItem
          onClick={() => navigate("/account/change-password")}
          data-test-id="change-password-button"
          role="button"
          autoFocus={true}
          divider={true}
        >
          Change password
        </MenuItem>
        <MenuItem
          onClick={signOut}
          data-test-id="sign-out-button"
          role="button"
        >
          Sign out
        </MenuItem>
      </Menu>
    </>
  )
}

export const ImpersonationBanner = (): ReactElement => {
  const { applicationNumber, admin, profile } =
    SignedInLayout.useUserWithSignInRedirect()

  const path = SignedInLayout.usePath()

  const borderWidth = "4px"

  const ImpersonationBorder = styled(Box)(() => ({
    position: "fixed",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    borderColor: colours.indigo5,
    borderStyle: "solid",
    borderLeftWidth: borderWidth,
    borderTopWidth: borderWidth,
  }))

  if (!admin.status.isImpersonating || !profile) return <></>

  // Don't flash up the banner while not on application related pages.
  if (!path || !/^application.*/.test(path)) {
    return <></>
  }

  return (
    <>
      <AppBar
        sx={{ marginTop: "80px", height: "60px" }}
        position="absolute"
        component="div"
        elevation={0}
        color="secondary"
        data-test-id="impersonation-banner"
      >
        <Container sx={{ display: "flex" }}>
          <T
            color="white"
            sx={{ flex: "auto", pt: 2 }}
            variant="body2"
            component="p"
          >
            You are viewing{" "}
            <T
              component="span"
              color="white"
              sx={{ fontWeight: "fontWeightBold" }}
              variant="body2"
              title={profile.email}
            >
              {profile?.forenames} {profile?.surname} (application{" "}
              {applicationNumber})
            </T>
          </T>
          <Link
            component="button"
            onClick={() => {
              admin.actions.stopImpersonation()
            }}
          >
            Stop viewing application
          </Link>
        </Container>
      </AppBar>
      <ImpersonationBorder role="display" sx={{ right: "auto" }} />
      <ImpersonationBorder role="display" sx={{ left: "auto" }} />
      <ImpersonationBorder role="display" sx={{ top: "auto" }} />
      <ImpersonationBorder role="display" sx={{ bottom: "auto" }} />
    </>
  )
}

const ApplicationHeader = (): ReactElement => {
  return (
    <>
      <AppBar color="primary" elevation={1} position="absolute">
        <Container
          maxWidth={false}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            my: "auto",
          }}
          component="nav"
        >
          <Link to="/application" sx={{ p: 1 }}>
            <Logo
              sx={{
                width: 172,
              }}
            />
            <Box sx={visuallyHidden}>Home</Box>
          </Link>
          <UserMenu />
        </Container>
      </AppBar>
      <ImpersonationBanner />
    </>
  )
}

export const SignedInLayout = ({
  showHomeLink = true,
  homeLink = "/application",
  children,
}: PropsWithChildren<{
  showHomeLink?: boolean
  homeLink?: string
}>): ReactElement => {
  const { admin } = SignedInLayout.useUserWithSignInRedirect()

  return (
    <QuestionAnswerProvider>
      <aside>
        <SkipNav />
      </aside>
      <ApplicationHeader />
      <Container
        component="main"
        sx={{
          mt: admin.status.isImpersonating ? "140px" : "80px",
          pt: 4,
          mb: [8, 12],
        }}
      >
        <SkipNavContent />
        <NoScript />
        <AlertsProvider>
          <SignedInLayout.Alerts />
        </AlertsProvider>
        {showHomeLink && (
          <T variant="body1" sx={{ mb: [3, 5] }}>
            <HomeLink to={homeLink} data-test-id="home-link">
              <ArrowBackIcon />
              Home
            </HomeLink>
          </T>
        )}
        <Box>{children}</Box>
      </Container>
      <Footer />
    </QuestionAnswerProvider>
  )
}

// Setting these as static members allows us to mock during component testing
SignedInLayout.useUserWithSignInRedirect = useUserWithSignInRedirect
SignedInLayout.Alerts = Alerts
SignedInLayout.usePath = usePath

export default SignedInLayout
