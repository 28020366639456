import { collection, query as firestoreQuery, where } from "firebase/firestore"
import { useFirebase } from "../providers/FirebaseProvider"
import React, { FC, createContext, useEffect, useState } from "react"
import { Alert } from "@ap-frontend/types"
import { onSnapshot } from "firebase/firestore"

export const AlertsContext = createContext<Alert[]>([])
AlertsContext.displayName = "AlertsContext"

export const AlertsProvider: FC = ({ children }) => {
  const [data, setData] = useState<Alert[]>([])

  const { firestore } = useFirebase()

  useEffect(() => {
    if (!firestore) return

    const query = firestoreQuery(
      collection(firestore, "alerts"),
      // Alerts that are enabled
      where("display.enabled", "==", true),
      // Has a valid severity
      where("severity", "in", ["error", "warning", "info", "success"]),
      // Has a message
      // "!=" and "not-in" queries exclude documents where the given field does not exist
      // https://cloud.google.com/firestore/docs/query-data/queries#not_equal_
      where("message", "!=", "")
      // We can't orderBy here to get them newest first
      // As we have a != where clause
      // And for some reason, firestore insists you orderBy that field first
      // So sort manually below
    )

    const unsubscribe = onSnapshot(query, QuerySnapshot => {
      const alerts: Alert[] = []
      QuerySnapshot.forEach(doc => {
        alerts.push({ ...doc.data(), id: doc.id } as unknown as Alert)
      })

      setData(alerts.sort((a, b) => (a.created > b.created ? 1 : -1)))
    })

    return () => {
      unsubscribe()
    }
  }, [firestore])

  return (
    <AlertsContext.Provider value={data}>{children}</AlertsContext.Provider>
  )
}
