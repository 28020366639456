import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useState,
} from "react"
import {
  signInWithEmailAndPassword as firebaseSignIn,
  signOut as firebaseSignOut,
  sendPasswordResetEmail as firebaseSendPasswordResetEmail,
  verifyPasswordResetCode as firebaseVerifyPasswordResetCode,
  confirmPasswordReset as firebaseConfirmPasswordReset,
  updatePassword as firebaseUpdatePassword,
  User as FirebaseUser,
  ParsedToken,
} from "firebase/auth"
import { useFirebase } from "./FirebaseProvider"
import { useFirestoreDoc } from "../hooks/useFirestoreDoc"
import { UserProfile, UserActivity, ActivityKeys } from "@ap-frontend/types"
import { FieldValue, SnapshotMetadata } from "firebase/firestore"
export {
  UserActivity,
  UserProfile,
  ActivityKeys,
  FormalQualification,
} from "@ap-frontend/types"

interface Token extends ParsedToken {
  name: string
  applicationNumber: string
}

interface AdminRoles {
  impersonation: boolean
  audit: boolean
  retrySubmission: boolean
}

type DocumentStatus = "Fetched" | "Error" | "Pending" | "Inactive"

export interface UserAdmin {
  access: boolean
  roles: AdminRoles | undefined
  status: {
    isImpersonating: boolean
    documents: DocumentStatus
  }
  actions: {
    impersonateApplication: (newApplicationNumber: string) => void
    loadApplication: (newApplicationNumber: string) => void
    stopImpersonation: () => void
  }
}

export interface User {
  /**
   * The current logged in user
   *
   * It is:
   * - A User object if the user is logged in
   * - null if the user is not logged in
   * - undefined if the current status is unknown (eg. during rehydration)
   */
  user: FirebaseUser | null | undefined
  admin: UserAdmin
  applicationNumber: string | null | undefined
  profile: UserProfile | undefined
  activity: UserActivity | undefined
  signIn: (email: string, password: string) => Promise<void>
  signOut: () => Promise<void>
  updateActivity: <ActivityKeyType extends ActivityKeys>(
    activityKey: ActivityKeyType,
    value: UserActivity[ActivityKeyType]
  ) => Promise<void>
  sendPasswordResetEmail: (email: string) => Promise<void>
  verifyResetPassword: (oobCode: string) => Promise<void>
  resetPassword: (oobCode: string, password: string) => Promise<void>
  updatePassword: (password: string) => Promise<void>
  metadata: {
    activity: SnapshotMetadata | undefined
  }
}

export const UserContext = createContext<User | undefined>(undefined)
UserContext.displayName = "UserContext"

export const UserProvider: FC = ({ children }) => {
  const [user, setUser] = useState<FirebaseUser | null | undefined>(undefined)
  const [applicationNumber, setApplicationNumber] = useState<
    string | null | undefined
  >(undefined)
  const [isAdmin, setIsAdmin] = useState<boolean>(false)
  const [isImpersonating, setIsImpersonating] = useState<boolean>(false)
  const [adminRoles, setAdminRoles] = useState<AdminRoles | undefined>()
  const [activity, setActivity] = useState<UserActivity | undefined>()
  const [activityMetadata, setActivityMetadata] = useState<
    SnapshotMetadata | undefined
  >(undefined)
  const [profile, setProfile] = useState<UserProfile | undefined>()

  const [documentStatus, setDocumentStatus] =
    useState<DocumentStatus>("Inactive")

  const profileDoc = useFirestoreDoc<UserProfile>(
    applicationNumber && `applicants/${applicationNumber}/userReadonly/profile`
  )

  const activityDoc = useFirestoreDoc<UserActivity>(
    applicationNumber && `applicants/${applicationNumber}/user/activity`
  )

  useEffect(() => {
    if (!applicationNumber) {
      setDocumentStatus("Inactive")

      setProfile(undefined)

      setActivity(undefined)
      setActivityMetadata(undefined)

      return
    }

    if (
      profileDoc.data &&
      profileDoc.data?.applicationNumber === applicationNumber &&
      activityDoc.data
    ) {
      setProfile(profileDoc.data)

      setActivity(activityDoc.data)
      setActivityMetadata(activityDoc.metadata)

      setDocumentStatus("Fetched")
      return
    }

    // Don't keep previous documents metadata
    setActivityMetadata(undefined)

    if (profileDoc.error) {
      setDocumentStatus("Error")
      return
    }

    if (activityDoc.error) {
      setDocumentStatus("Error")
      return
    }

    setDocumentStatus("Pending")
  }, [applicationNumber, activityDoc, profileDoc])

  const { app, auth } = useFirebase()

  const setAuthProps = async (user: FirebaseUser | null | undefined) => {
    if (user) {
      try {
        const jwt = await user.getIdToken()
        const token: Token = JSON.parse(atob(jwt.split(".")[1]))
        const {
          applicationNumber,
          admin,
          impersonation,
          audit,
          retrySubmission,
        } = token
        if (applicationNumber) {
          setApplicationNumber(applicationNumber)
          setIsAdmin(false)
          setAdminRoles(undefined)
        } else if (typeof admin === "boolean" && admin) {
          setIsAdmin(admin)
          setAdminRoles({
            impersonation:
              (typeof impersonation === "boolean" && impersonation) || false,
            audit: (typeof audit === "boolean" && audit) || false,
            retrySubmission:
              (typeof retrySubmission === "boolean" && retrySubmission) ||
              false,
          })
        }
      } catch (e) {
        console.warn("Error fetching user token", e)
      }

      setUser(user)
    } else {
      setUser(null)
      setApplicationNumber(null)
    }
  }

  const updateActivity = async <ActivityKeyType extends ActivityKeys>(
    activityKey: ActivityKeyType,
    value: UserActivity[ActivityKeyType]
  ) => {
    if (!user) throw new Error("app/not-authenticated")

    if (typeof activity === "undefined")
      throw new Error("app/activity-not-fetched")

    if (
      value instanceof FieldValue &&
      activity[activityKey] instanceof FieldValue &&
      value.isEqual(activity[activityKey] as FieldValue)
    ) {
      return
    } else if (
      !(value instanceof FieldValue) &&
      activity[activityKey] === value
    ) {
      return
    }

    activityDoc.mutate({
      ...activity,
      [activityKey]: value,
    })
  }

  const sendPasswordResetEmail = async (email: string) => {
    if (!auth) throw new Error("app/auth-not-ready")

    await firebaseSendPasswordResetEmail(auth, email)
  }

  const verifyResetPassword = async (oobCode: string) => {
    if (!auth) throw new Error("app/auth-not-ready")

    await firebaseVerifyPasswordResetCode(auth, oobCode)
  }

  const resetPassword = async (oobCode: string, password: string) => {
    if (!auth) throw new Error("app/auth-not-ready")

    await firebaseConfirmPasswordReset(auth, oobCode, password)
  }

  const updatePassword = async (password: string) => {
    if (!user) throw new Error("app/auth-not-ready")

    await firebaseUpdatePassword(user, password)
  }

  const signIn = async (email: string, password: string) => {
    const userCreds = auth && (await firebaseSignIn(auth, email, password))
    setAuthProps(userCreds?.user)
  }

  const signOut = async () => {
    auth && (await firebaseSignOut(auth))
    setAuthProps(null)
    setApplicationNumber(undefined)
    setIsAdmin(false)
    setAdminRoles(undefined)
  }

  const loadApplication = (newApplicationNumber: string) => {
    if (!adminRoles?.impersonation && !adminRoles?.audit) {
      throw new Error("user-provider/user-data/access-denied")
    }

    setApplicationNumber(newApplicationNumber)
  }

  const impersonateApplication = (newApplicationNumber: string) => {
    if (!adminRoles?.impersonation) {
      throw new Error("user-provider/impersonation/access-denied")
    }

    if (newApplicationNumber !== applicationNumber)
      loadApplication(newApplicationNumber)

    setIsImpersonating(true)
  }

  const stopImpersonation = () => {
    if (!adminRoles?.impersonation) {
      throw new Error("user-provider/impersonation/access-denied")
    }

    setIsImpersonating(false)
  }

  useEffect(() => {
    auth && auth.onAuthStateChanged(user => setAuthProps(user))
  }, [app, auth])

  const value = {
    user,
    admin: {
      access: isAdmin,
      roles: adminRoles,
      status: {
        isImpersonating,
        documents: documentStatus,
      },
      actions: {
        impersonateApplication,
        stopImpersonation,
        loadApplication,
      },
    },
    applicationNumber,
    profile,
    activity,
    signIn,
    signOut,
    updateActivity,
    sendPasswordResetEmail,
    resetPassword,
    verifyResetPassword,
    updatePassword,
    metadata: {
      activity: activityMetadata,
    },
  }

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

export const useUser: () => User = () => {
  const userCtx = useContext(UserContext)
  if (userCtx === undefined) {
    throw Error("useUser hook must be used inside an UserProvider")
  }
  return userCtx
}
