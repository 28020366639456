module.exports = [{
      plugin: require('../plugins/gatsby-plugin-top-layout/gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/.pnpm/gatsby-plugin-material-ui@4.1.0_@emotion+react@11.11.1_gatsby@4.25.7/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToEmotionCacheProps":""},
    },{
      plugin: require('../../node_modules/.pnpm/gatsby-plugin-manifest@4.25.0_gatsby@4.25.7_graphql@15.8.0/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"My Cambridge Application","short_name":"mycapp","start_url":"/","background_color":"#FEFFFE","theme_color":"#098283","display":"minimal-ui","icon":"src/images/UniversityOfCambridge_Shield-icon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e48cd2b74813ad2ba8d8fa77a0b8bc27"},
    },{
      plugin: require('../../node_modules/.pnpm/gatsby@4.25.7_babel-eslint@10.1.0_react-dom@17.0.2_react@17.0.2_typescript@4.9.5_webpack-dev-server@3.11.3/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
