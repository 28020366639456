import React, { FC } from "react"
import {
  default as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
} from "@mui/material/Checkbox"
import FormControlLabel from "@mui/material/FormControlLabel"
import { styled } from "@mui/material/styles"

const StyledCheckbox = styled(MuiCheckbox)(() => ({
  // We don't currently override styles, however we predict we will after testing
  // Leaving this boilerplate in here to pick up from at that point
}))

const StyledCheckboxLabel = styled(FormControlLabel)(() => ({
  marginLeft: 0,
}))

type AdditionalCheckboxProps = {
  /**
   * A text label to be display alongside the Checkbox element
   */
  label?: string
  /**
   * Handle displaying in dark backgrounds
   */
  dark?: boolean
}

type CheckboxProps = AdditionalCheckboxProps &
  Omit<
    MuiCheckboxProps,
    | "classes"
    | "icon"
    | "checkedIcon"
    | "indeterminateIcon"
    | "slot"
    | "disableRipple"
    | "disableTouchRipple"
    | "disableFocusRipple"
    | "size"
  >

/**
 * Wrapper than controls the root component
 * If a label is supplied wrap our Checkbox in the Material ControlLabel
 */
const CheckboxWrapper = React.forwardRef<
  HTMLButtonElement,
  AdditionalCheckboxProps & MuiCheckboxProps
  // eslint-disable-next-line react/prop-types
>(function Wrapper({ label, dark = false, ...props }, ref) {
  return label ? (
    <StyledCheckboxLabel
      label={label}
      control={
        <StyledCheckbox
          className={dark ? "MuiCheckbox-dark" : ""}
          {...props}
          ref={ref}
        />
      }
      sx={dark ? { color: "common.white" } : {}}
    />
  ) : (
    <StyledCheckbox
      className={dark ? "MuiCheckbox-dark" : ""}
      {...props}
      ref={ref}
    />
  )
})

const Checkbox: FC<CheckboxProps> = React.forwardRef<
  HTMLButtonElement,
  CheckboxProps
>(function Checkbox({ color = "primary", ...props }, ref) {
  return <CheckboxWrapper color={color} ref={ref} {...props} />
})

Checkbox.propTypes = {
  ...(Checkbox as FC<CheckboxProps>).propTypes,
}

export default Checkbox
