/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as React from "react"
import TopLayout from "./TopLayout"
import { UserProvider } from "../../src/providers/UserProvider"
import { GatsbyBrowser } from "gatsby"
import { FirebaseProvider } from "../../src/providers/FirebaseProvider"
import { ErrorBoundary } from "react-error-boundary"
import ErrorFallback from "../../src/components/ErrorFallback"
export { wrapPageElement } from "./wrap-page-element"
import CookieBanner from "../../src/components/CookieBanner"

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({
  element,
}) => {
  return (
    <FirebaseProvider>
      <TopLayout>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <CookieBanner />
          <UserProvider>{element}</UserProvider>
        </ErrorBoundary>
      </TopLayout>
    </FirebaseProvider>
  )
}
