import Alert from "@mui/material/Alert"
import React from "react"
import { Typography as T } from "@mui/material"

const NoScript = () => {
  return (
    <noscript>
      <Alert sx={{ mb: 2 }} severity="error">
        <T variant="body1">
          You need to enable JavaScript.
          <br />
          My Cambridge Application doesn&apos;t work without JavaScript.
        </T>
      </Alert>
    </noscript>
  )
}

export default NoScript
